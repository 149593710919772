.submit-button {
  min-width: 180px;
  padding: 16px;
  background-color: #2b2b2c;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-size: 16px;
  z-index: 1;
}

.submit-button-white-round {
  border-radius: 12px;
  width: 124px;
  height: 43px;
  background-color: #f5f5f5;
  color: #2b2b2c;
  border: none;
  cursor: pointer;
}

.submit-button-disabled {
  color: #fff;
  background-color: #c3c3c3;
  cursor: default;
}
