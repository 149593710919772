@import url("https://fast.fonts.net/cssapi/89b15ae3-041a-4056-a712-2f1fe1dd5431.css");

@font-face {
  font-family: "StudioFeixenSans";
  src: local("StudioFeixenSans"),
    url("./fonts/StudioFeixenSans/StudioFeixenSans-Bold.ttf") format("truetype");
  font-weight: bold;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "StudioFeixenSans";
  letter-spacing: -0.015em; /* adjust letter spacing depending on font size */
  word-spacing: -0.1em; /* adjust word spacing depending on font size */
  -webkit-font-smoothing: antialiased; /* looks better */
  -moz-osx-font-smoothing: grayscale; /* looks better */
  font-size: 18px;
  background-color: transparent;
}
span {
  font-size: 14px;
}
.wrapper-main-container {
  min-height: 100vh;
  width: 100%;
  background-color: #f0f0f0;
}
.main-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 40px;
}

@media (min-width: 900px) {
  body {
    margin: 0 auto;
  }
  h1 {
    font-size: 2.5em;
    line-height: 1;
  }
}
